import React from "react";
import Footer from "./Footer";

const Team = () => {
  return (
    <div>
      <div className="container">
        {/* First Section */}
        <section className="team-section bg-color">
          <div className="team-row"> {/* Changed from row to team-row */}
            <div className="col-md-6">
              <h2>Porque cuidar no tiene por qué ser difícil</h2>
            </div>
            <div className="col-md-6">
              <img src="/FotosLP/Team.png" alt="Vision" className="img-fluid circular-img" /> {/* Added circular-img class */}
            </div>
          </div>
        </section>

        {/* Second Section */}
        <section className="team-section white-bg">
          <div className="team-row"> {/* Changed from row to team-row */}
            <div className="col-md-6">
              <img src="/FotosLP/Papus.jpg" alt="Mission" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <h2>Acerca de Myosotis</h2>
              <p>A finales de 2023, cinco estudiantes de ingeniería civil informática, impulsados por su pasión por la tecnología y su vocación por el cuidado de los adultos mayores, se unieron para crear Myosotis, una empresa con el propósito de transformar el cuidado de los más vulnerables.</p>
              <p>Myosotis como empresa tiene como misión facilitar el cuidado de adultos mayores.</p>
              <p>La visión que tenemos es la de un mundo donde los adultos mayores vivan con seguridad y dignidad, evitando riesgos innecesarios gracias a la entrega oportuna de información, aliviando la carga de los cuidadores.</p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Team;
