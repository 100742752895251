import React from "react";
import './Footer.css'; // Optional: for custom styling
import logo from "../Develop.png";
const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-section about">
          <h4>Acerca de GerioTracker</h4>
          <p>
          GerioTracker es un sistema de monitoreo activo para la posición
          de adultos mayores en un hogar de tercera edad. 
          </p>
        </div>
        <div className="footer-section social-media">
          <h4>Contactanos</h4>
          <div>
            <a href="https://www.instagram.com/geriotracker/" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-instagram"></i> Instagram
            </a>
            <a href="https://www.linkedin.com/company/geriotracker/" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-linkedin"></i> LinkedIn
            </a>
            <a href="mailto:myosotis.fsw@gmail.com">
              <i className="fa fa-envelope"></i> Email
            </a>
          </div>
        </div>
        <div className="footer-section developer">
          <h4>Desarrollado por</h4>
          <div className="developer-logo">
            <img src={logo} alt="Developer Logo" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
