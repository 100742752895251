import React, { useState } from "react";
import emailjs from "emailjs-com";
import Footer from "./Footer";

const initialState = {
  name: "",
  email: "",
  message: "",
};

const Contact = () => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // Add modal visibility state
  const [modalMessage, setModalMessage] = useState(""); // Add message for modal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm("service_ny3pqvh", "template_32ed101", e.target, "z6gWEeFbz3q94wGa1")
      .then(
        (result) => {
          console.log(result.text);
          setModalMessage("Mensaje enviado correctamente"); // Set success message
          setShowModal(true); // Show modal
          clearState();
        },
        (error) => {
          console.log(error.text);
          setModalMessage("Hubo un error en el envio de su mesnaje, intente nuevamente mas tarde"); // Set error message
          setShowModal(true); // Show modal
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {/* Modal popup for feedback */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
      
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="section-title">
              <h2>Ponte en contacto con Nosotros</h2>
              <p>
                Por favor, rellenar la siguiente informacion y nos contacataremos contigo tan pronto nos sea posible
              </p>
            </div>
            <form name="sentMessage" validate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Nombre"
                      required
                      onChange={handleChange}
                      disabled={loading}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                      disabled={loading}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Mensaje"
                  required
                  onChange={handleChange}
                  disabled={loading}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div id="success"></div>
              <button type="submit" className="btn btn-custom btn-lg" disabled={loading}>
                {loading ? "Sending..." : "Enviar Mensaje"}
              </button>
            </form>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informacion de Contacto</h3>
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                myosotis.fsw@gmail.com
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-instagram"></i> Instagram
                </span>{" "}
                <a href="https://www.instagram.com/geriotracker/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-linkedin"></i> LinkedIn
                </span>{" "}
                <a href="https://www.linkedin.com/company/geriotracker/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <br></br>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
