import React from "react";
import Footer from "./Footer";
import './Main.css'; // Assuming we'll use an external CSS file

const Main = () => {
  const contactInfo = {
    instagram: "https://instagram.com/yourcompany",
    linkedin: "https://linkedin.com/company/yourcompany",
    email: "info@yourcompany.com",
  };

  return (
    <div>
      {/* First section with background image and title */}
      <div className="hero-section">
        <div className="overlay">
          <h1 className="title">¿Que es GerioTracker?</h1>
        </div>
        {/* First YouTube video */}
        <div className="video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/4tr3ylaSpAM?si=wB34fYuWWhiVXECJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

      {/* Second section with title and video */}
      <div className="second-section">
        <h2 className="pitch-title">Video Pitch</h2>
        <div className="video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/uyyX4YPI_10?si=Z5doVaUB0NKurn1d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Main;
