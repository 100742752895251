import React from "react";
import Footer from "./Footer"; // Import the Footer component
import peopleData from "../data/peopleData.json";

export const People = () => {
  return (
    <div id="people" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Conoce al Equipo</h2>
          <p>
            Haz click en las fotos para acceder a sus Linkedin
          </p>
        </div>
        <div className="row team-row2">
          {peopleData.map((member, i) => (
            <div key={`${member.name}-${i}`} className="col-md-4 col-sm-6 team-member">
              <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <div className="thumbnail">
                  <img src={member.img} alt={member.name} className="team-img" />
                  <div className="caption">
                    <h4>{member.name}</h4>
                    <p>{member.role}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default People;
