import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import Team from "./components/Team";
import People from "./components/People";
import Contact from "./components/Contact";
import Navigation from "./components/Navigation";

const App = () => {
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/team" element={<Team />} />
          <Route path="/people" element={<People />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
