import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png"; // Import the logo SVG

const Navigation = () => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <Link  to="/">
            <img src={logo} alt="GerioTracker Logo" className="navbar-logo" style={{ height: '50px', width: 'auto' }} />
          </Link>
        </div>
        <div className="collapse navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/team">Nosotros</Link>
            </li>
            <li>
              <Link to="/people">Personas</Link>
            </li>
            <li>
              <Link to="/contact">Contactanos</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
